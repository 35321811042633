import "./certificate-page.css";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, EffectFlip, Keyboard, Mousewheel, Navigation, Pagination } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const CertificatePage = () => {
  document.title = "Citizen Tube | Certificate";
  return (
    <div className="certificate-page">
      <div className='mx-auto md:max-w-[600px]'>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
        >
        <SwiperSlide>
          <div className="flex justify-center">
            <img className="md:w-[400px]" src="/imgs/certificate-main-2.jpeg" alt="certificate" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex justify-center">
            <img className="md:w-[400px]" src="/imgs/certificate-main.jpg" alt="certificate" />
          </div>
        </SwiperSlide>
      </Swiper>
      </div>
    </div>
  );
};

export default CertificatePage;
