import { useState } from "react";
import PopUp from "../popup-window/popup";
import "./info.css";

const Info = ({ infoArr }) => {
  const [isPopupActive, setIsPopupActive] = useState(false);
  return (
    <div className="flex justify-start bg-white md:max-w-[1240px] mx-auto mt-20">
      <div>
          <div>
            <h2 className="text-6xl text-center mt-5 mb-10 font-bold text-[#2f5061]">What we provide</h2>
            <div className="flex justify-center">
              <div className="w-[200px] devidinganim h-[3px] bg-[#2f5061] mb-20"></div>
            </div>
          </div>
        <div className="gap-20">
          {
          infoArr.map(({id, image, heading, description }, i) =>
            <div className={id % 2 === 0 ? ` md:flex flex-row-reverse mb-14  md:p-0 p-5` : `md:flex mb-14 md:p-0 p-5`}>
                <img loading="eager" className={id % 2 === 0 ? `md:w-1/2 md:rounded-2xl h-[350px]`: `md:w-1/2 md:rounded-2xl h-[350px]`} src={image} alt="" />
                <div className={id % 2 === 0 ? `md:w-1/2 md:rounded-xl bg-white p-10`: `md:w-1/2 md:rounded-r-xl bg-white p-10`}>
                    <p className="text-gray-400 pb-2 uppercase border-l-4 border-solid border-gray-500 pl-3 mb-4">Citizen Tube Industries</p>
                    <h2 className="text-6xl text-black font-semibold">{heading}</h2>
                    <p className="mt-8 text-[#2E4F4F]">{description}</p>
                    <button onClick={() => setIsPopupActive(true)} className="px-10 py-3 hover:bg-[#E57F84] duration-500 font-semibold bg-[#2E4F4F] text-white uppercase rounded-2xl mt-8">Enquire</button>
                  {isPopupActive && <PopUp popupActive={setIsPopupActive} />}
                </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );

};

export default Info;
