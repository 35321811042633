import { Fragment, useState } from "react";
import {
  FaRegCheckSquare,
  FaRegEdit,
  FaRegEnvelopeOpen,
  FaRegMap,
} from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { FaMapMarkerAlt } from "react-icons/fa";

import { AiFillStar } from "react-icons/ai";


import Info from "../home-components/info";
import PopUp from "../popup-window/popup";
import Footer from "./footer";
import "./home-page.css";
import CertificatePage from "./certificate-page";
import { Link } from "react-router-dom";

const HomePage = () => {
  const [activatePopup, setActivatePopup] = useState(false);
  document.title = "Citizen Tube";
  const infoArr = [
    {
      id: 1,
      image: "/imgs/citizentube--img1.png",
      heading: "Citizen Tube Industries",
      description:
        "Citizen tube industry established in 1992, is one of the prominent and leading ISO-certified Indian company that manufacture and supply high-strength steel products for the past 30 years. CTI successfully and devotedly supplies a large number of steel products that include mild steel pipes, stainless steel sheets, nuts, bolts, beams, angle, stainless steel pipes, and can supply multiple customized steel products.",
    },
    {
      id: 2,
      image: "/imgs/citizentube--img2.jpg",
      heading: "Why Choose CTI ?",
      description:
        "CTI is one of the most reliable and well-known steel industry in India and has earned the trust and support of our customers on a national and international level by supplying advanced high-end steel products. There is an important test conducted on steel products to test the mechanical attainment of steel. Steel products are evaluated based on their yield point, tensile strength, and elongation percentage so that our customers won't have to compromise on the quality they want.",
    },
  ];

  // const reviewsArr = [
  //   {
  //     review:
  //       "In our search for steel products of sufficient quality, we encountered several challenges. Citizen Tube Industry ends our quest as soon as we begin sourcing from them. Their product performance is far superior to others.",
  //     place: "Mumbai",
  //     company: "Metal industrial corporation",
  //     rating: 5,
  //   },
  //   {
  //     review:
  //       "In our search for steel products of sufficient quality, we encountered several challenges. Citizen Tube Industry ends our quest as soon as we begin sourcing from them. Their product performance is far superior to others.",
  //     place: "Raipur",
  //     company: "Venus pipes and fittings",
  //     rating: 5,
  //   },
  //   {
  //     review:
  //       "In our search for steel products of sufficient quality, we encountered several challenges. Citizen Tube Industry ends our quest as soon as we begin sourcing from them. Their product performance is far superior to others.",
  //     place: "Kolkata",
  //     company: "Asian metal corporation",
  //     rating: 5,
  //   },
  // ];
  const testimonials = [
    {
        _id: 1,
        message: 'Citizen Tube Ind has proven high-tech and specialized expertise in customizing steel products. Their timely delivery and excellent services made working with them a pleasant experience.',
        company_name: 'Metal industrial corporation',
        address: 'Mumbai'
    },
    {
        _id: 2,
        message: 'In our search for steel products of sufficient quality, we encountered several challenges. Citizen Tube Ind ends our quest as soon as we begin sourcing from them. Their product performance is far superior to others. Venus pipes and fittings',
        company_name: 'Venus pipes and fittings',
        address: 'Raipur'
    },
    {
        _id: 3,
        message: 'It feels great to receive support during a difficult time. We have always relied on Citizen Tube Industries for help when we have faced problems. The way they addressed an issue and its solution in such a short time are awesome.',
        company_name: 'Asian metal corporation',
        address: 'Kolkata'
    },
    {
        _id: 4,
        message: 'Citizen Tube Ind is a company that has demonstrated exceptional proficiency and specialization in tailoring steel products to meet specific requirements. Their timely delivery and exceptional customer service have made collaborating with them an enjoyable experience.',
        company_name: 'Bhushan Steel Ltd',
        address: 'Maharashtra'
    },
    {
        _id: 5,
        message: "It's truly a relief to have someone to rely on during difficult times. Citizen Tube Ind has been our go-to for help whenever we've faced problems. Their impressive response time and efficient solutions are truly commendable. We're grateful for their unwavering support.",
        company_name: 'Alloyed Steel',
        address: 'Mumbai'
    }
]

  const serviceDetails = [
    {
      icon: <FaRegCheckSquare />,
      name: "Delivery and logistics",
      description:
        "Offer delivery and logistics services to ensure timely and efficient delivery of products to customers.",
    },
    {
      icon: <FaRegEdit />,
      name: "Technical support",
      description:
        "Provide technical support to customers to help them choose the right products for their applications.",
    },
    {
      icon: <FaRegEnvelopeOpen />,
      name: "Quality assurance",
      description:
        "Offer inspection and quality assurance services to ensure that customers receive products that meet their specifications and expectations.",
    },
    {
      icon: <FaRegMap />,
      name: "Value-added services",
      description:
        "Offer value-added services such as kitting, packaging, and labeling to help customers streamline their operations and reduce costs.",
    },
  ];

  return (
    <Fragment>
      <div className="home-section">
        <div className={`home-section-bg flex md:flex-row flex-col items-center md:px-20 px-5`}>
          <div className="md:w-1/2 md:mt-0 mt-20">
            <h2 className="text-[40px] font-bold text-white">Your Reliable Exporter And Supplier Of  High Strength Steel Products.</h2>
            <p className="text-white text-[18px]">
            At our steel company, we take pride in offering a wide range of steel products that meet the most stringent quality standards. From steel plates to pipes and tubes, we have an extensive inventory of high-quality steel products to cater to all your requirements. We source our steel products from the most reputable manufacturers in the industry, ensuring that you get only the best.
            </p>

            <div className="mt-10 flex gap-5">
              <Link to='/contact'>
                <button className="duration-500 bg-[#E57F84] hover:bg-transparent border-transparent border-2 hover:border-[#E57F84] border-solid text-white px-14 py-5 rounded-md uppercase font-semibold">Contact us</button>
              </Link>

              <Link to='about'>
                <button className="duration-500 bg-transparent hover:bg-transparent hover:bg-[#2f5061] border-2 border-[#E57F84] hover:border-transparent border-solid text-white px-14 py-5 rounded-md uppercase font-semibold">About  us</button>
              </Link>
            </div>
          </div>

          {/* right side  */}
          <div className="md:w-1/2 md:flex items-center justify-center  hidden">
            <img className="rightsideLogo" src="https://i.ibb.co/CBCH0qN/citizentube-ind-logo-removebg-preview-1.png" alt="" />
          </div>
        </div>

        <Info infoArr={infoArr} />

        <div className="bg-[#E57F84] flex justify-center h-[250px] items-center mt-20">
          <div className='border-y-2 border-white py-5 px-20'>
            <h1 className=" text-4xl mb-3 text-white">We are ISO Certified by</h1>
            <div className="h-[45px]">
              <h1 className="textAnim text-6xl mb-10 text-white font-bold">"TUV Austria"</h1>
            </div>
            <h2 className="text-gray-100 text-center text-3xl">EN ISO 9001</h2>
            <h3 className="text-gray-100 text-center text-3xl mt-3">Certificate No: <span className="text-white font-semibold">20100223015588</span></h3>
          </div>
        </div>

      {/* certification  */}
      <div className="pt-20 mt-6">
        <div>
          <h2 className="text-6xl text-center mt-5  font-bold text-[#2F5061]">Certification</h2>
            <div className="flex justify-center">
              <div className="w-[200px] devidinganim h-[3px] bg-[#2F5061] mb-20"></div>
            </div>
        </div>
        <CertificatePage></CertificatePage>
      </div>

        {/* testimonials section   */}
        <div className="md:max-w-[1200px] md:px-0 px-5 mx-auto py-10 mb-20">
          <div classname="">
            <h2 className="text-6xl text-center mt-5 mb-10 font-bold text-[#2F5061]">What People says</h2>
            <div className="flex justify-center">
              <div className="w-[200px] devidinganim h-[3px] bg-[#2F5061] mb-20"></div>
            </div>
          <Swiper
        style={{
          paddingLeft: "50px",
          paddingRight: "50px"
      }}
      cssMode={true}
      navigation={true}
      pagination={true}
      mousewheel={true}
      keyboard={true}
      spaceBetween={30}
      modules={[Navigation, Pagination, Mousewheel, Keyboard]}
      className="mySwiper"
      breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 1
          },
          // when window width is >= 768px
          768: {
            slidesPerView: 3
          }
        }}
      >
        {
                    testimonials.map((t) => 
                    <SwiperSlide className=''>
                        <div className='bg-base-100 p-3 hover:shadow-xl rounded-t-xl border-b-4 border-gray-200 hover:border-b-4 hover:border-accent duration-700 cursor-pointer'>
                    <div className='h-[250px] '>
                    <div className='flex justify-end opacity-80 py-3'>
                        <img className='w-6' src="https://i.ibb.co/T2nGMtb/2087858.png" alt="" />
                    </div>
                    <p className='mb-2 text-justify break-all'>{t.message}</p>
                    </div>

                    <div className='flex justify-between'>
                        <p className='text-primary font-semibold'>{t.company_name}</p>  
                        <p className='text-accent font-semibold flex items-center '><FaMapMarkerAlt></FaMapMarkerAlt> {t.address}</p>  
                    </div>
                    <div className='flex justify-end text-yellow-500 mt-3 text-lg'>
                        <AiFillStar></AiFillStar>
                        <AiFillStar></AiFillStar>
                        <AiFillStar></AiFillStar>
                        <AiFillStar></AiFillStar>
                        <AiFillStar></AiFillStar>
                    </div> 
                    </div>
                    </SwiperSlide>
                    )
        }
      </Swiper>
          </div>
        </div>
        {/* <AboutUs /> */}
      </div>
      <Footer />
      {activatePopup && <PopUp popupActive={setActivatePopup} />}
    </Fragment>
  );
};

export default HomePage;
